.case-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.header-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.header-search {
  margin-right: 1em;
}
