table {
  border-collapse: collapse;
  width: 100%;
  font-size: 12px;
}
th,
td {
  text-align: left;
  padding: 8px;
}
td:first-child {
  width: 50%;
}
tr:nth-child(even) {
  background-color: #f2f2f2;
}
tr:hover {
  background-color: #ddd;
}
th {
  background-color: #4d728a;
  color: white;
}
td {
  border-bottom: 1px solid #ddd;
}
td:nth-child(3) {
  text-align: center;
}
td:nth-child(4) {
  text-align: left;
}
