:root {
  --category-internal-background: #e74c3c;
  --category-introducer-background: #3498db;
  --category-solicitor-background: #27ae60;
  --category-external-background: #f10fdc;
}

body {
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  min-height: 100%;
}
.p-datatable .p-datatable-tbody > tr > td {
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: 600;
}

.p-datatable .p-datatable-thead > tr > th {
  padding: 10px 12px !important;
}

.p-datatable .p-sortable-column .p-column-title {
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  font-weight: 700;
}

body {
  line-height: 1.42857143;
  color: #333;
  background-color: #fff;
}
.btn {
  padding: 7px 16px 8px 16px;
  font-size: 12px;
  font-weight: 600;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  transition: 200ms ease-in-out;
}
.pull-right {
  float: right !important;
}
.btn-primary {
  color: #fff;
  background-color: #546672;
  border-color: #546672;
}

.btn-primary:hover {
  color: #fff;
  background-color: #546672;
  border-color: #546672;
  box-shadow: 0 2px 5px #8d9092;
}
.btn-warning {
  color: white;
  background-color: #e48561;
  border-color: #e48561;
}
.btn-warning:hover {
  color: white;
  background-color: #e48561;
  border-color: #e48561;
  box-shadow: 0 2px 5px #8d9092;
}
.btn-default {
  color: #333;
  background-color: #fff;
  border: 1px solid #ccc !important;
}
.btn-default:hover {
  color: #333;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 2px 5px #8d9092;
}
.btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  border-radius: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.well {
  padding: 15px;
  margin-bottom: 0;
  background-color: #fcfcfc;
  border-color: #e5e5e5;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  box-shadow: none;
}
.form-control {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 0;
  -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  -webkit-transition: border-color ease-in-out 0.15s,
    box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
label {
  font-weight: 600;
}

label {
  display: inline-block;
  margin-bottom: 5px;
  font-weight: 700;
}
.p-inputtext {
  padding: 7px 9px 8px 9px !important;
  font-size: 12px !important;
  border-color: #ddd !important;
  box-shadow: none !important;
  border-radius: 0 !important;
}
.p-inputtext-error {
  border-color: red !important;
}
.p-inputtext-error:enabled:focus {
  border-color: red !important;
}
.p-inputtext-error:enabled:hover {
  border-color: red !important;
}
.label__error {
  margin-top: 6px;
  margin-bottom: 0;
  color: #fff;
  background-color: #d65c4f;
  padding: 5px 8px;
  font-size: 11px;
  font-weight: 600;
  line-height: 14px;
  width: 60px;
}
.label__error_100 {
  margin-top: 6px;
  margin-bottom: 0;
  color: #fff;
  background-color: #d65c4f;
  padding: 5px 8px;
  font-size: 11px;
  font-weight: 600;
  line-height: 14px;
  width: 100%;
}
label {
  margin-bottom: 4px !important;
  margin-top: 4px !important;
  font-size: 12px !important;
  font-weight: 600 !important;
}
.p-dropdown {
  width: 30% !important;
  border-radius: 0 !important;
}
.dropdown__width__100 {
  width: 100% !important;
}
.Width__50 {
  width: 50% !important;
}
.Width__100 {
  width: 100% !important;
}
.Panel__Heading {
  border-top-left-radius: 2px !important;
  border-top-right-radius: 2px !important;
  padding: 0 !important;
  position: relative !important;
  border-bottom: 1px dashed #eee !important;
  margin-bottom: 40px !important;
  font-size: 13px !important;
  font-weight: 600 !important;
}
.text-semibold {
  font-weight: 600;
}

.p-dialog .p-dialog-header .p-dialog-title {
  font-weight: 700;
  font-size: 12px !important;
}

.p-dialog .p-dialog-header {
  background: none !important;
  background-color: #65b688 !important;
  padding: 0px !important;
  color: white !important;
  padding-top: 2px !important;
  padding-left: 15px !important;
  padding-bottom: 4px !important;
}

.p-dialog .p-dialog-header .p-dialog-header-icon {
  color: #fff !important;
}
.p-checkbox .p-checkbox-box {
  border: 1px solid #ced4da !important;
  border-radius: 0 !important;
}
[disabledForm~="true"] input,
[disabledForm~="true"] .p-dropdown,
[disabledForm~="true"] textarea {
  pointer-events: none !important;
  background: rgb(245, 245, 245) !important;
}

[disabledForm~="true"] .p-checkbox,
[disabledForm~="true"] .p-radiobutton {
  opacity: 0.65;
  pointer-events: none !important;
}
[disabledForm~="true"] button {
  pointer-events: none !important;
  opacity: 0.65;
}
[disabledForm~="true"] .p-multiselect {
  pointer-events: none !important;
  opacity: 0.65;
}

[disabledForm~="true"] #print-btn {
  pointer-events: all !important;
  opacity: 1;
}

[disabledForm~="true"] #showmorebtn {
  pointer-events: all !important;
  opacity: 1;
}

.p-dialog-header-close-icon {
  font-size: xx-small !important;
}

.p-link:focus {
  outline: 0 none;
  box-shadow: none !important;
}

.pointer {
  cursor: pointer;
}
.btn-primary {
  background-color: #546672 !important;
  border-color: #546672 !important;
}

.btn {
  padding: 7px 16px 8px 16px !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  border-radius: 2px !important;
  -webkit-border-radius: 2px !important;
  -moz-border-radius: 2px !important;
}
.border__hr {
  border-top-color: rgb(238, 238, 238);
  border-top-style: solid;
  border-top-width: 1px;
  border-right-color: initial;
  border-right-style: initial;
  border-right-width: 0px;
  border-bottom-color: initial;
  border-bottom-style: initial;
  border-bottom-width: 0px;
  border-left-color: initial;
  border-left-style: initial;
  border-left-width: 0px;
  border-image-source: initial;
  border-image-slice: initial;
  border-image-width: initial;
  border-image-outset: initial;
  border-image-repeat: initial;
  border-top: 1px solid #eee;
  height: 1.3px !important;
}
.border__hr__dashed {
  border-top-color: rgb(238, 238, 238);
  border-top-style: solid;
  border-top-width: 1px;
  border-right-color: initial;
  border-right-style: initial;
  border-right-width: 0px;
  border-bottom-color: initial;
  border-bottom-style: initial;
  border-bottom-width: 0px;
  border-left-color: initial;
  border-left-style: initial;
  border-left-width: 0px;
  border-image-source: initial;
  border-image-slice: initial;
  border-image-width: initial;
  border-image-outset: initial;
  border-image-repeat: initial;
  border-top: 1px dashed #eee;
  height: 1.3px !important;
}
.layout > div {
  position: initial !important;
  padding-bottom: 2em;
}

.timeline-demo .custom-marker {
  display: flex;
  width: 2rem;
  height: 2rem;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  border-radius: 50%;
  z-index: 1;
}

.timeline-demo .p-timeline-event-content,
.timeline-demo .p-timeline-event-opposite {
  line-height: 1;
}

@media screen and (max-width: 960px) {
  .timeline-demo .customized-timeline .p-timeline-event:nth-child(even) {
    flex-direction: row !important;
  }
  .timeline-demo
    .customized-timeline
    .p-timeline-event:nth-child(even)
    .p-timeline-event-content {
    text-align: left !important;
  }
  .timeline-demo .customized-timeline .p-timeline-event-opposite {
    flex: 0;
  }
  .timeline-demo .customized-timeline .p-card {
    margin-top: 1rem;
  }
}

.btn-sm {
  padding: 6px 12px 7px 12px !important;
  font-size: 12px !important;
  line-height: 1.42857143 !important;
  border-radius: 2px !important;
}

.btn-info {
  background-color: #50abc2 !important;
  border-color: #50abc2 !important;
}
.box {
  margin-bottom: 12px;
}
.expanded-card {
  background-color: rgb(245, 248, 250);
  padding: 1.5rem;
  font-size: 90%;
}
.custom-chip {
  background-color: rgb(196, 225, 255) !important;
}
.custom-chip-warning {
  background-color: #df4848 !important;
  display: inline-block !important;
  font-size: 11px !important;
  padding: 5px 8px 6px 8px !important;
  line-height: 13px !important;
  font-weight: 600 !important;
  color: #fff !important;
  border-radius: 2px !important;
  -webkit-border-radius: 2px !important;
  -moz-border-radius: 2px !important;
  min-width: 100px;
}
.p-splitbutton * {
  border-radius: 0 !important;
}

.p-splitbutton-menubutton {
  background-color: #4c5d68 !important;
  border-color: #4c5d68 !important;
  width: 1.7rem !important;
  height: 33px;
}
.p-splitbutton-defaultbutton {
  background-color: #4c5d68 !important;
  border-color: #4c5d68 !important;
  width: 1.7rem !important;
  height: 33px;
}
.p-menu ul {
  font-size: 12px;
  max-height: 500px;
  overflow-y: scroll;
}
.pl-1 {
  padding-left: 1rem;
}

.p-inputtext.p-invalid.p-component {
  border-color: #e24c4c !important;
}

//hide on print
@media print {
  .hide-on-print {
    display: none;
  }
}

.html2canvas-container {
  width: 100vw;
  height: 100vw;
}

.label-info {
  background-color: #3ca2bb;
  margin-top: 6px;
  display: block;
  font-size: 11px;
  padding: 5px 8px 6px 8px;
  line-height: 13px;
  font-weight: 600;
  color: #fff;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  width: 50%;
  text-align: center;
}

.label-danger {
  background-color: #d65c4f;
  margin-top: 6px;
  display: block;
  font-size: 11px;
  padding: 5px 8px 6px 8px;
  line-height: 13px;
  font-weight: 600;
  color: #fff;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  width: 50%;
  text-align: center;
}

.custom-chip {
  background-color: #3ca2bb !important;
  display: inline-block !important;
  font-size: 11px !important;
  padding: 5px 8px 6px 8px !important;
  line-height: 13px !important;
  font-weight: 600 !important;
  color: #fff !important;
  border-radius: 2px !important;
  -webkit-border-radius: 2px !important;
  -moz-border-radius: 2px !important;
  min-width: 100px;
}

.history-tl-container {
  margin-left: 20px;
  font-family: "Roboto", sans-serif;
  width: 100%;
  display: block;
  position: relative;
  max-height: 50vh;
  overflow-y: scroll;
}
.history-tl-container ul.tl {
  margin-top: 20px;
  margin-bottom: 0 !important;
  padding: 0;
  display: inline-block;
}
.history-tl-container ul.tl li {
  list-style: none;
  margin: auto;
  /*background: rgba(255,255,0,0.1);*/
  border-left: 1px dashed black;
  padding: 0 0 50px 30px;
  position: relative;
}
.history-tl-container ul.tl li:last-child {
  border-left: 0;
}
.history-tl-container ul.tl li::before {
  position: absolute;
  left: -6px;
  top: -5px;
  content: " ";
  border: 6px solid black;
  border-radius: 500%;
  background: black;
  height: 12px;
  width: 12px;
  transition: all 500ms ease-in-out;
}
.history-tl-container ul.tl li:hover::before {
  border-color: black;
  transition: all 1000ms ease-in-out;
}
ul.tl li .item-title {
}
ul.tl li .item-detail {
  color: rgba(0, 0, 0, 0.5);
  font-size: 12px;
}
ul.tl li .timestamp {
  color: black;
  position: absolute;
  width: 20px;
  left: -50%;
  text-align: right;
  font-size: 10px;
}

.p-progressbar {
  height: 1rem !important;
}
.p-progressbar-label {
  font-size: 0.75rem !important;
  line-height: normal !important;
}

.Radio__Btn {
  display: flex;
  margin-top: 8px;

  RadioButton {
    padding-right: 10px;
  }
}

.p-tabview .p-tabview-nav .p-tabview-ink-bar {
  z-index: 1;
  display: block;
  position: absolute;
  bottom: 0;
  height: 2px;
  background-color: #60a714 !important;
  transition: 500ms cubic-bezier(0.35, 0, 0.25, 1);
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  background: #ffffff;
  border-color: #2e510a !important;
  color: #2e510a !important;
}

.p-column-filter-overlay-menu
  .p-column-filter-constraint
  .p-column-filter-matchmode-dropdown {
  width: 100% !important;
}

.p-column-filter-operator .p-dropdown {
  width: 100% !important;
}

.ql-container {
  min-height: 10rem;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.ql-editor {
  height: 100%;
  flex: 1;
  overflow-y: auto;
  width: 100%;
}
