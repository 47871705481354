.utils-component {
  display: flex;
  justify-content: space-between;
  padding: 4px 7px;
  border: 0.5px solid #ddd;
  width: 100%;
  align-items: center;
  margin: 0 0.2rem;
  cursor: pointer;
  transition: 300ms ease-in-out;
}

.utils-component .icon_container {
  display: flex;
  align-items: center;
}
.icon_container .text {
  padding: 1px;
  font-size: 12px;
}

.icon_container .icon {
  padding: 1px;
  font-size: 16px;
}

.utils-component .count_container {
  color: var(--clr-white);
  background-color: var(--clr-primary);
  border-radius: 2px;
  padding: 5px 8px;
}

.active {
  background-color: var(--clr-active);
  color: var(--clr-white);
}

/* #################### */
/*  largTab  Wrapper style */
/* #################### */

.form-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .form-header {
    flex-direction: column;
  }
}
