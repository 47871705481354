.tabs-list {
  padding-bottom: 20px !important;
  margin-bottom: 20px;
  display: flex;
  flex: flex-start;
  flex-wrap: wrap;
  border-bottom: 1px solid #ddd;
}
.tab {
  margin-top: 10px;
  display: flex;
  align-items: center;
  margin-right: -1px;
  border-radius: 0;
  font-weight: 600;
  color: #333;
  padding: 11px 14px 12px 14px;
  transition: 200ms;
  border: 1px solid white;
  cursor: pointer;
  margin-right: 0px;
}
.tab:hover {
  border: 1px solid rgb(226, 226, 226);
  color: #676767;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  background-color: #fefefe;
}
.tab-active {
  color: #428bca;
  border: 1px solid #ddd;
  border-top: 1px solid #d65c4f;
  border-bottom-color: transparent;
}
.tab-active:hover {
  color: #428bca;
  border: 1px solid #ddd;
  border-top: 1px solid #d65c4f;
  border-bottom-color: transparent;
  cursor: default;
}
.tab-count {
  font-size: 11px;
  padding: 3px 5px 3px 5px;
  line-height: 13px;
  font-weight: 600;
  color: #fff;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  background-color: #445661;
  margin-left: 5px;
}
