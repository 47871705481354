.navbar-default {
  background-color: #fafafa;
  border-bottom: 1px solid #e5e5e5;
  border-color: #e5e5e5;
}

.navbar-fixed-top,
.navbar-fixed-bottom {
  position: fixed;
}
.navbar {
  z-index: 1000;
  min-height: 54px;
  border-radius: 0;
  margin: 0;
  border: 0;
  display: flex;
  align-items: center;
}
.navbar-default {
  background-color: #f8f8f8;
  border-color: #e7e7e7;
}
.navbar-fixed-top {
  top: 0;
  border-width: 0 0 1px;
}
@media (min-width: 768px) {
  .navbar-fixed-top,
  .navbar-fixed-bottom {
    border-radius: 0;
  }
}
.navbar-fixed-top,
.navbar-fixed-bottom {
  position: fixed;
  right: 0;
  left: 0;
  z-index: 1030;
}
@media (min-width: 768px) {
  .navbar {
    border-radius: 4px;
  }
}
.navbar {
  position: relative;
  min-height: 50px;
  margin-bottom: 20px;
  border: 1px solid transparent;
}
.navbar-default .navbar-brand {
  color: #777;
}

.navbar-brand {
  padding-top: 8px;
}
.navbar-brand {
  height: 54px;
  padding: 14px 15px 0 0;
}
.navbar-brand {
  /* float: left; */
  padding: 15px;
  font-size: 18px;
  line-height: 20px;
}
.navbar-medium {
  display: flex;
}
.navbar-dropdown {
  margin-left: 10px;
  font-size: small;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.nav-menu {
  position: absolute;
  top: 50px;
  background: #fff;
  border: 1px solid lightgray;
  min-width: 130px;
}
.nav-menu-item {
  cursor: pointer;
  padding: 5px 10px;
  transition: 250ms;
}
.nav-menu-item:hover {
  background-color: var(--clr-primary);
  color: white;
}
