.page-header {
  padding: 0;
  margin: 0;
  position: relative;
  border: 0;
}

.page-header {
  padding-bottom: 9px;
  margin: 40px 0 20px;
}
.page-title {
  float: left;
}
.page-title h3 {
  padding: 29px 0;
  margin: 0;
  font-weight: 400;
  line-height: 20px;
}
.page-header small {
  display: block;
  font-size: 11px;
  color: #999;
  margin: 8px 0 0 0;
}
.full-width .page-content {
  margin-bottom: 60px;
}

.full-width .page-content {
  margin: 0 25px;
}
@media (max-width: 991px) {
  .page-content {
    margin: 0 25px 0 25px;
  }
}
.page-content {
  margin: 0 25px 0 265px;
  position: relative;
}
