.accordion-header {
  border-bottom: 1px dashed lightgray;
}
.accordion-header > span {
  cursor: pointer;
  font-size: small;
}
.accordion-body {
  transition: 250ms;
}
