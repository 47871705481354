.chat-window {
  flex: auto;
  flex-direction: row-reverse;
  max-height: calc(100% - 60px);
  background: #f3f3ef;
  overflow: auto;
  padding: 0.5rem 0rem;
}
.chat-window::-webkit-scrollbar {
  width: 8px;
}
.chat-window::-webkit-scrollbar-thumb {
  background-color: #746d85;
  border-radius: 0px;
}
.chat-input {
  flex: 0 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 120px;
  background: #40434e;
  border-top: 1px solid #1ea40e;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.28);
}
.chat-input span {
  color: white;
}
.chat-input select {
  color: white;
  background-color: #40434e;
  border: none;
  padding: 8px;
  font-weight: bold;
  outline: none;
}
.chat-input textarea {
  resize: none;
  border: none;
  width: calc(80% - 60px);
  color: white;
  background: #40434e;
}
.chat-input button {
  float: right;
  outline: 0 none;
  border: none;
  background: #1ea40e;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  padding: 2px 0 0 0;
  margin: 10px;
  transition: all 0.15s ease-in-out;
  cursor: pointer;
  transition: 100ms;
}
.chat-input button:hover {
  transform: scale(1.07);
  box-shadow: 0px 0px 4px rgb(34, 34, 34);
}

.chat-input input[good] + button {
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.24);
  background: #1ea40e;
}
.chat-input input[good] + button:hover {
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.chat-input input[good] + button path {
  fill: white;
}
.chat-input i {
  color: white;
  font-size: 120%;
}
.msg-container {
  position: relative;
  display: inline-block;
  width: 100%;
  margin: 0 0 10px 0;
  padding: 0;
}
.msg-box {
  display: flex;
  padding: 5px 10px 5px 10px;
  border-radius: 0 6px 6px 0;
  max-width: 80%;
  width: auto;
  float: left;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.24);
  transition: 200ms;
}
.msg-box:hover {
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2), 0 2px 4px rgba(0, 0, 0, 0.6);
}
.user-img {
  display: inline-block;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  background: #1ea40e;
  margin: 0 10px 10px 0;
}
.flr {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  width: calc(100% - 50px);
}
.messages {
  flex: 1 0 auto;
}
.msg {
  display: inline-block;
  font-size: 11pt;
  line-height: 13pt;
  color: rgba(255, 255, 255, 0.9);
  margin: 0 0 4px 0;
}
.msg:first-of-type {
  margin-top: 8px;
}
.timestamp {
  color: rgba(255, 255, 255, 0.5);
  font-size: 8pt;
  margin-bottom: 10px;
  margin-top: 5px;
}
.username {
  margin-right: 3px;
}
.posttime {
  margin-left: 3px;
}
.msg-self .msg-box {
  border-radius: 6px 0 0 6px;
  background: #1ea40e;
  float: right;
}
.msg-internal .msg-box {
  border-radius: 6px 0 0 6px;
  background: var(--category-internal-background);
  float: right;
}
.msg-solicitor .msg-box {
  border-radius: 6px 0 0 6px;
  background: var(--category-solicitor-background);
  float: right;
}
.msg-introducer .msg-box {
  border-radius: 6px 0 0 6px;
  background: var(--category-introducer-background);
  float: right;
}
.msg-thirdparty .msg-box {
  border-radius: 6px 0 0 6px;
  background: var(--category-external-background);
  float: right;
}
.msg-self .user-img {
  margin: 0 0 10px 10px;
}
.msg-self .msg {
  text-align: right;
}
.msg-self .timestamp {
  text-align: right;
}
.sending {
  opacity: 0.8;
}
.blink {
  -webkit-animation: blink 1000ms step-end infinite;
  animation: blink 1000ms step-end infinite;
  transition: 200ms;
}
@-webkit-keyframes blink {
  100% {
    transform: scale(1.2);
  }
}
@keyframes blink {
  50% {
    transform: scale(1.2);
  }
}

.color-introducer {
  background: var(--category-introducer-background);
}
.color-solicitor {
  background: var(--category-solicitor-background);
}

.color-internal {
  background: var(--category-internal-background);
}

.color-thirdparty {
  background: var(--category-external-background);
}

.usercat {
  color: rgba(255, 255, 255, 0.5);
  font-size: 8pt;
  margin-bottom: 5px;
  margin-top: 2px;
}

.highlighted {
  background-color: yellow; /* Change this to your desired highlight effect */
}
