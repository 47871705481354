.header {
    display: flex;
    justify-content: space-between;
}
.mb-10px {
    margin-bottom: 10px !important;
}
.mr-2 {
    margin-right: 2% !important;
}
.heading-3 {
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
    font-size: 1.5rem;
}
