.modal-footer-text {
  font-size: 11px !important;
}
.files-table-header {
  background-color: var(--clr-primary);
  padding: 9px 12px 9px 12px;
}
.files-table-body {
  padding: 9px 12px 9px 12px;
  font-weight: 600;
  font-size: 12px;
  max-height: 40vh;
  overflow-y: scroll;
}
.files-table-body > div {
  padding: 10px 12px;
  background: #fff;
  border-bottom: 1px solid #eee;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: rgb(238, 238, 238);
}
.files-table-header > div {
  color: white;
  float: left;
  overflow: hidden;
  font-weight: 600;
  font-size: 12px;
}
.upload-modal .p-dialog-content {
  padding: 0 0rem 2rem 0rem !important;
}
.add-files {
  display: inline-block;
  text-decoration: none;
  color: #fff;
  background-color: #50abc2;
  border: 1px solid transparent;
  padding: 6px 16px;
  margin-right: 4px;
  font-size: 12px;
  transition: 200ms ease-in-out;
}
.add-files:hover {
  box-shadow: 0 2px 5px #8d9092;
}

.start-upload {
  display: inline-block;
  text-decoration: none;
  color: #fff;
  background-color: #65b688;
  border: 1px solid transparent;
  padding: 6px 16px;
  margin-right: 4px;
  font-size: 12px;
  transition: 200ms ease-in-out;
}

.start-upload:hover {
  box-shadow: 0 2px 5px #8d9092;
}
.start-upload:disabled {
  opacity: 0.6;
  box-shadow: none !important;
}

.start-upload:disabled:hover {
  opacity: 0.6;
  box-shadow: none !important;
}
.upload-footer {
  padding: 0px 12px 0px 12px;
  font-weight: 600;
  font-size: 13px;
}
