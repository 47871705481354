/* CategoryColors.css */

.category-colors {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 20px;
}

.category {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.color {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

.color:hover {
  transform: scale(1.1);
}

.category-internal {
  background: var(--category-internal-background);
  color: #fff;
}

.category-introducer {
  background: var(--category-introducer-background);
  color: #fff;
}

.category-solicitor {
  background: var(--category-solicitor-background);
  color: #fff;
}

.category-thirdparty {
  background: var(--category-external-background);
  color: #fff;
}

.category-info {
  margin-left: 10px;
}

.category-info p {
  margin: 0;
  font-size: 1rem;
  font-weight: bold;
}

.category:hover .category-info p {
  color: #000;
}
