.info-blocks {
  list-style: none;
  width: 100%;
  text-align: center;
  margin: 0;
  margin-bottom: 30px;
  padding: 0;
  color: #fff;
}
.info-blocks > li {
  display: inline-block;
  text-align: center;
  min-width: 150px;
  white-space: nowrap;
  margin: 0 10px 5px 10px;
}

.statistics-info > a,
.daterangepicker td,
.daterangepicker th,
.info-blocks > li,
.search-line,
.jqstooltip,
.plupload_button,
.jGrowl .jGrowl-notification,
.user-face,
.fc-button,
.ui-datepicker .ui-datepicker-current-day .ui-state-active,
.ui-datepicker .ui-state-hover,
.chart-tooltip,
.jGrowl .jGrowl-closer,
.popup-header a,
.has-switch,
.breadcrumb-line,
.chat .message .message-body,
.message-img img,
.fancybox-title-float-wrap .child,
#fancybox-loading,
.chat-member img,
.ranges,
.daterangepicker.opensleft .calendar,
.calendars,
.daterangepicker,
.editor,
.dataTables_paginate a,
.DTTT_collection,
label.error {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}
.bg-primary,
.bg-primary a {
  color: #fff;
  background-color: #32434d;
}
.bg-primary {
  color: #fff;
  background-color: #428bca;
}
.top-info {
  margin: 16px 14px 14px 14px;
}
.bottom-info {
  display: block;
  font-size: 11px;
  padding: 4px 8px 5px 8px;
  font-weight: 600;
  border-radius: 0 0 2px 2px;
  -webkit-border-radius: 0 0 2px 2px;
  -moz-border-radius: 0 0 2px 2px;
}
.top-info > a {
  display: block;
  font-weight: 600;
  text-transform: uppercase;
  color: #fff;
}
.bg-danger,
.bg-danger a {
  color: #fff;
  background-color: #d65c4f !important;
}
.bg-primary,
.bg-primary a {
  color: #fff;
  background-color: #32434d !important;
}
.bg-success,
.bg-success a {
  color: #fff;
  background-color: #65b688 !important;
}
.bg-info,
.bg-info a {
  color: #fff;
  background-color: #3ca2bb !important;
}
