.form-sideBox_section {
  display: flex;
  max-width: 100%;
  justify-content: space-between;
  gap: 2rem;
  margin-top: 1.5rem;
}

.form-sideBox_section .form-section {
  width: 75%;
}

@media only screen and (max-width: 992px) {
  .form-sideBox_section {
    flex-direction: column;
    width: 100%;
  }
  .form-sideBox_section .form-section {
    width: 100%;
  }
}
