.login-wrapper {
  width: 300px;
  position: absolute;
  left: 50%;
  top: 50%;
  width: 300px;
  margin-left: -150px;
  margin-top: -150px;
}
.box-shadow {
  border: 1px solid #ccc;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  border-radius: 6px;
  -webkit-box-shadow: 0 0 6px rgb(0 0 0 / 20%);
  -moz-box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
  -ms-box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 6px rgb(0 0 0 / 20%);
}
.popup-header {
  background: #32434d;
  text-align: center;
  color: #fff;
  -moz-border-radius: 2px 2px 0 0;
  -webkit-border-radius: 2px 2px 0 0;
  border-radius: 2px 2px 0 0;
}
.popup-header > span {
  display: inline-block;
  margin: 11px 12px 12px 12px;
}
.input-icon {
  position: absolute;
  top: 25px;
  right: 0;
  display: block;
  width: 34px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  color: #999;
  font-size: large;
}
.has-feedback {
  position: relative;
}
