.side-box {
    width: 100%;
    border: 1px solid var(--clr-primary);
}

.side-box .sideBox-header {
    height: 2.5rem;
    background-color: var(--clr-primary);
    color: var(--clr-white);
    display: flex;
    padding: 7px;
    justify-content: space-between;
    align-items: center;
    font-weight: 400;
}

.side-box .sideBox-Body {
    min-height: 5rem;
    padding: 7px;
}

/* ################### */
/*  sideBox wrapper style  */
/* ################### */

.side-box_section {
    width: 25%;
}

@media only screen and (max-width: 992px) {
    .side-box_section {
        width: 100%;
    }
}
