.panel-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.panel > hr {
  margin-top: 10px;
  border: 1px solid #1992c2;
}

.panel-right {
  cursor: pointer;
}

.panel-right > .actions > span {
  padding-left: 20px;
}
