.documentItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 1rem;
}

.doc-img {
  transition: 300ms;
  cursor: pointer;
  height: 420px;
  width: 420px;
  object-fit: contain;
  background: black;
}
.doc-img:hover {
  transform: scale(1.02);
  box-shadow: 0px 5px 2px lightgrey;
}

.multimedia-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
}

.zip-preview {
  display: flex;
  align-items: center;
}

.zip-icon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
  color: #f39c12; /* Optional: color the icon */
}
