.info-active {
  color: #428bca;
  border-color: #428bca;
  cursor: default;
}

.info-button {
  white-space: nowrap;
  padding: 16px;
  display: block;
  color: #505050;
  text-align: center;
  position: relative;
  background-color: #fafafa;
  border: 1px solid #ddd;
  margin-left: 1em;
  cursor: pointer;
  transition: 200ms;
}

.info-button:hover {
  background-color: #fcfcfc;
  border-color: #e5e5e5;
  color: #2a6496;
}

.info-button-count {
  font-size: 9px;
  padding: 2px;
  font-weight: 600;
  color: #fff;
  -webkit-border-radius: 2px;
  border-radius: 0px;
  background-color: #445661;
  position: absolute;
  top: 0;
  right: 0;
}

.info-list {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}
